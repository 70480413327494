.ds-thumbnail {
  min-width: 100px;
  max-width: 120px;
  position: relative; }
  .ds-thumbnail .image {
    background-color: #f8f8f9;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #f8f8f9;
    border-radius: 5px;
    margin-bottom: 0;
    padding-top: 55%;
    width: 100%;
    height: 0;
    position: relative; }
    .ds-thumbnail .image .video-player {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0; }
    .ds-thumbnail .image .placeholder {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      top: 50%;
      left: 50%; }
      .ds-thumbnail .image .placeholder span {
        margin-left: 10px; }
      .ds-thumbnail .image .placeholder i {
        font-size: 16px !important; }
  .ds-thumbnail .icon {
    width: 100%;
    border: 1px solid #f8f8f9;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .ds-thumbnail .icon .label {
      color: #969faa;
      font-weight: 300;
      margin: 0;
      padding: 0;
      padding-top: 5px; }
  .ds-thumbnail.tiny {
    min-width: 100px;
    max-width: 120px; }
    .ds-thumbnail.tiny i {
      font-size: 14px !important; }
    .ds-thumbnail.tiny .icon {
      min-height: 55px; }
      .ds-thumbnail.tiny .icon i {
        font-size: 14px !important; }
      .ds-thumbnail.tiny .icon .label {
        font-size: 10px;
        line-height: 12px; }
  .ds-thumbnail.small {
    min-width: 105px;
    max-width: 110px; }
    .ds-thumbnail.small i {
      font-size: 20px !important; }
    .ds-thumbnail.small .icon {
      min-height: 70px; }
      .ds-thumbnail.small .icon i {
        font-size: 18px !important; }
      .ds-thumbnail.small .icon .label {
        font-size: 12px;
        line-height: 16px; }
  .ds-thumbnail.regular {
    min-width: 160px;
    max-width: 180px; }
    .ds-thumbnail.regular i {
      font-size: 22px !important; }
    .ds-thumbnail.regular .icon {
      min-height: 85px; }
      .ds-thumbnail.regular .icon i {
        font-size: 20px !important; }
      .ds-thumbnail.regular .icon .label {
        color: #969faa;
        font-size: 14px;
        line-height: 18px; }
  .ds-thumbnail.medium {
    min-width: 320px;
    max-width: 350px; }
    .ds-thumbnail.medium i {
      font-size: 26px !important; }
    .ds-thumbnail.medium .icon {
      min-height: 175px; }
      .ds-thumbnail.medium .icon i {
        font-size: 24px !important; }
      .ds-thumbnail.medium .icon .label {
        color: #969faa;
        font-size: 18px;
        line-height: 22px; }
  .ds-thumbnail.large {
    min-width: 470px;
    max-width: 500px; }
    .ds-thumbnail.large i {
      font-size: 32px !important; }
    .ds-thumbnail.large .icon {
      min-height: 260px; }
      .ds-thumbnail.large .icon i {
        font-size: 28px !important; }
      .ds-thumbnail.large .icon .label {
        color: #969faa;
        font-size: 20px;
        line-height: 26px; }
  .ds-thumbnail.responsive {
    min-width: 100px;
    max-width: 1500px; }
    .ds-thumbnail.responsive i {
      font-size: 14px !important; }

@media screen and (max-width: 576px) {
  .ds-thumbnail.tiny {
    min-width: 70px;
    max-width: 100px; } }
