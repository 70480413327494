.ds-checkbox {
  display: -webkit-flex;
  display: flex; }
  .ds-checkbox .checkmark.tiny {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

@-webkit-keyframes dothabottomcheck-18px {
  0% {
    height: 0; }
  100% {
    height: 5.4px; } }

@keyframes dothabottomcheck-18px {
  0% {
    height: 0; }
  100% {
    height: 5.4px; } }

@keyframes dothatopcheck-18px {
  0% {
    height: 0; }
  50% {
    height: 0; }
  100% {
    height: 9px; } }

@-webkit-keyframes dothatopcheck-18px {
  0% {
    height: 0; }
  50% {
    height: 0; }
  100% {
    height: 9px; } }
    .ds-checkbox .checkmark.tiny input[type='checkbox'] {
      display: none; }
    .ds-checkbox .checkmark.tiny label {
      margin-bottom: 0; }
    .ds-checkbox .checkmark.tiny .check-box {
      height: 18px;
      width: 18px;
      background-color: transparent;
      border: 1px solid #969faa;
      border-radius: 9px;
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      transition: border-color ease 0.2s;
      cursor: pointer; }
      .ds-checkbox .checkmark.tiny .check-box::before, .ds-checkbox .checkmark.tiny .check-box::after {
        box-sizing: border-box;
        position: absolute;
        height: 0;
        width: 1px;
        background-color: gold !important;
        display: inline-block;
        -webkit-transform-origin: left top;
        transform-origin: left top;
        border-radius: 5px;
        content: ' ';
        transition: opacity ease 0.5; }
      .ds-checkbox .checkmark.tiny .check-box::before {
        top: 12.24px;
        left: 7.02px;
        box-shadow: 0 0 0 0.9px #fff;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg); }
      .ds-checkbox .checkmark.tiny .check-box::after {
        top: 9px;
        left: 3.24px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    .ds-checkbox .checkmark.tiny input[type='checkbox']:checked + .check-box,
    .ds-checkbox .checkmark.tiny .check-box.checked {
      border-color: gold !important; }
      .ds-checkbox .checkmark.tiny input[type='checkbox']:checked + .check-box::after,
      .ds-checkbox .checkmark.tiny .check-box.checked::after {
        height: 9px;
        -webkit-animation: dothabottomcheck-18px 0.2s ease 0s forwards;
        animation: dothabottomcheck-18px 0.2s ease 0s forwards; }
      .ds-checkbox .checkmark.tiny input[type='checkbox']:checked + .check-box::before,
      .ds-checkbox .checkmark.tiny .check-box.checked::before {
        height: 21.6px;
        -webkit-animation: dothatopcheck-18px 0.4s ease 0s forwards;
        animation: dothatopcheck-18px 0.4s ease 0s forwards; }
  .ds-checkbox .checkmark.small {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

@-webkit-keyframes dothabottomcheck-22px {
  0% {
    height: 0; }
  100% {
    height: 6.6px; } }

@keyframes dothabottomcheck-22px {
  0% {
    height: 0; }
  100% {
    height: 6.6px; } }

@keyframes dothatopcheck-22px {
  0% {
    height: 0; }
  50% {
    height: 0; }
  100% {
    height: 11px; } }

@-webkit-keyframes dothatopcheck-22px {
  0% {
    height: 0; }
  50% {
    height: 0; }
  100% {
    height: 11px; } }
    .ds-checkbox .checkmark.small input[type='checkbox'] {
      display: none; }
    .ds-checkbox .checkmark.small label {
      margin-bottom: 0; }
    .ds-checkbox .checkmark.small .check-box {
      height: 22px;
      width: 22px;
      background-color: transparent;
      border: 1px solid #969faa;
      border-radius: 11px;
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      transition: border-color ease 0.2s;
      cursor: pointer; }
      .ds-checkbox .checkmark.small .check-box::before, .ds-checkbox .checkmark.small .check-box::after {
        box-sizing: border-box;
        position: absolute;
        height: 0;
        width: 1px;
        background-color: gold !important;
        display: inline-block;
        -webkit-transform-origin: left top;
        transform-origin: left top;
        border-radius: 5px;
        content: ' ';
        transition: opacity ease 0.5; }
      .ds-checkbox .checkmark.small .check-box::before {
        top: 14.96px;
        left: 8.58px;
        box-shadow: 0 0 0 1.1px #fff;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg); }
      .ds-checkbox .checkmark.small .check-box::after {
        top: 11px;
        left: 3.96px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    .ds-checkbox .checkmark.small input[type='checkbox']:checked + .check-box,
    .ds-checkbox .checkmark.small .check-box.checked {
      border-color: gold !important; }
      .ds-checkbox .checkmark.small input[type='checkbox']:checked + .check-box::after,
      .ds-checkbox .checkmark.small .check-box.checked::after {
        height: 11px;
        -webkit-animation: dothabottomcheck-22px 0.2s ease 0s forwards;
        animation: dothabottomcheck-22px 0.2s ease 0s forwards; }
      .ds-checkbox .checkmark.small input[type='checkbox']:checked + .check-box::before,
      .ds-checkbox .checkmark.small .check-box.checked::before {
        height: 26.4px;
        -webkit-animation: dothatopcheck-22px 0.4s ease 0s forwards;
        animation: dothatopcheck-22px 0.4s ease 0s forwards; }
  .ds-checkbox .checkmark.regular {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

@-webkit-keyframes dothabottomcheck-26px {
  0% {
    height: 0; }
  100% {
    height: 7.8px; } }

@keyframes dothabottomcheck-26px {
  0% {
    height: 0; }
  100% {
    height: 7.8px; } }

@keyframes dothatopcheck-26px {
  0% {
    height: 0; }
  50% {
    height: 0; }
  100% {
    height: 13px; } }

@-webkit-keyframes dothatopcheck-26px {
  0% {
    height: 0; }
  50% {
    height: 0; }
  100% {
    height: 13px; } }
    .ds-checkbox .checkmark.regular input[type='checkbox'] {
      display: none; }
    .ds-checkbox .checkmark.regular label {
      margin-bottom: 0; }
    .ds-checkbox .checkmark.regular .check-box {
      height: 26px;
      width: 26px;
      background-color: transparent;
      border: 1px solid #969faa;
      border-radius: 13px;
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      transition: border-color ease 0.2s;
      cursor: pointer; }
      .ds-checkbox .checkmark.regular .check-box::before, .ds-checkbox .checkmark.regular .check-box::after {
        box-sizing: border-box;
        position: absolute;
        height: 0;
        width: 1px;
        background-color: gold !important;
        display: inline-block;
        -webkit-transform-origin: left top;
        transform-origin: left top;
        border-radius: 5px;
        content: ' ';
        transition: opacity ease 0.5; }
      .ds-checkbox .checkmark.regular .check-box::before {
        top: 17.68px;
        left: 10.14px;
        box-shadow: 0 0 0 1.3px #fff;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg); }
      .ds-checkbox .checkmark.regular .check-box::after {
        top: 13px;
        left: 4.68px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    .ds-checkbox .checkmark.regular input[type='checkbox']:checked + .check-box,
    .ds-checkbox .checkmark.regular .check-box.checked {
      border-color: gold !important; }
      .ds-checkbox .checkmark.regular input[type='checkbox']:checked + .check-box::after,
      .ds-checkbox .checkmark.regular .check-box.checked::after {
        height: 13px;
        -webkit-animation: dothabottomcheck-26px 0.2s ease 0s forwards;
        animation: dothabottomcheck-26px 0.2s ease 0s forwards; }
      .ds-checkbox .checkmark.regular input[type='checkbox']:checked + .check-box::before,
      .ds-checkbox .checkmark.regular .check-box.checked::before {
        height: 31.2px;
        -webkit-animation: dothatopcheck-26px 0.4s ease 0s forwards;
        animation: dothatopcheck-26px 0.4s ease 0s forwards; }
    @media screen and (max-width: 768px) {
      @-webkit-keyframes dothabottomcheck-25px {
        0% {
          height: 0; }
        100% {
          height: 7.5px; } }
      @keyframes dothabottomcheck-25px {
        0% {
          height: 0; }
        100% {
          height: 7.5px; } }
      @keyframes dothatopcheck-25px {
        0% {
          height: 0; }
        50% {
          height: 0; }
        100% {
          height: 12.5px; } }
      @-webkit-keyframes dothatopcheck-25px {
        0% {
          height: 0; }
        50% {
          height: 0; }
        100% {
          height: 12.5px; } }
      .ds-checkbox .checkmark.regular input[type='checkbox'] {
        display: none; }
      .ds-checkbox .checkmark.regular label {
        margin-bottom: 0; }
      .ds-checkbox .checkmark.regular .check-box {
        height: 25px;
        width: 25px;
        background-color: transparent;
        border: 1px solid #969faa;
        border-radius: 12.5px;
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        transition: border-color ease 0.2s;
        cursor: pointer; }
        .ds-checkbox .checkmark.regular .check-box::before, .ds-checkbox .checkmark.regular .check-box::after {
          box-sizing: border-box;
          position: absolute;
          height: 0;
          width: 1px;
          background-color: gold !important;
          display: inline-block;
          -webkit-transform-origin: left top;
          transform-origin: left top;
          border-radius: 5px;
          content: ' ';
          transition: opacity ease 0.5; }
        .ds-checkbox .checkmark.regular .check-box::before {
          top: 17px;
          left: 9.75px;
          box-shadow: 0 0 0 1.25px #fff;
          -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg); }
        .ds-checkbox .checkmark.regular .check-box::after {
          top: 12.5px;
          left: 4.5px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }
      .ds-checkbox .checkmark.regular input[type='checkbox']:checked + .check-box,
      .ds-checkbox .checkmark.regular .check-box.checked {
        border-color: gold !important; }
        .ds-checkbox .checkmark.regular input[type='checkbox']:checked + .check-box::after,
        .ds-checkbox .checkmark.regular .check-box.checked::after {
          height: 12.5px;
          -webkit-animation: dothabottomcheck-25px 0.2s ease 0s forwards;
          animation: dothabottomcheck-25px 0.2s ease 0s forwards; }
        .ds-checkbox .checkmark.regular input[type='checkbox']:checked + .check-box::before,
        .ds-checkbox .checkmark.regular .check-box.checked::before {
          height: 30px;
          -webkit-animation: dothatopcheck-25px 0.4s ease 0s forwards;
          animation: dothatopcheck-25px 0.4s ease 0s forwards; } }
  .ds-checkbox .checkmark.large {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

@-webkit-keyframes dothabottomcheck-30px {
  0% {
    height: 0; }
  100% {
    height: 9px; } }

@keyframes dothabottomcheck-30px {
  0% {
    height: 0; }
  100% {
    height: 9px; } }

@keyframes dothatopcheck-30px {
  0% {
    height: 0; }
  50% {
    height: 0; }
  100% {
    height: 15px; } }

@-webkit-keyframes dothatopcheck-30px {
  0% {
    height: 0; }
  50% {
    height: 0; }
  100% {
    height: 15px; } }
    .ds-checkbox .checkmark.large input[type='checkbox'] {
      display: none; }
    .ds-checkbox .checkmark.large label {
      margin-bottom: 0; }
    .ds-checkbox .checkmark.large .check-box {
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: 1px solid #969faa;
      border-radius: 15px;
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      transition: border-color ease 0.2s;
      cursor: pointer; }
      .ds-checkbox .checkmark.large .check-box::before, .ds-checkbox .checkmark.large .check-box::after {
        box-sizing: border-box;
        position: absolute;
        height: 0;
        width: 1px;
        background-color: gold !important;
        display: inline-block;
        -webkit-transform-origin: left top;
        transform-origin: left top;
        border-radius: 5px;
        content: ' ';
        transition: opacity ease 0.5; }
      .ds-checkbox .checkmark.large .check-box::before {
        top: 20.4px;
        left: 11.7px;
        box-shadow: 0 0 0 1.5px #fff;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg); }
      .ds-checkbox .checkmark.large .check-box::after {
        top: 15px;
        left: 5.4px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    .ds-checkbox .checkmark.large input[type='checkbox']:checked + .check-box,
    .ds-checkbox .checkmark.large .check-box.checked {
      border-color: gold !important; }
      .ds-checkbox .checkmark.large input[type='checkbox']:checked + .check-box::after,
      .ds-checkbox .checkmark.large .check-box.checked::after {
        height: 15px;
        -webkit-animation: dothabottomcheck-30px 0.2s ease 0s forwards;
        animation: dothabottomcheck-30px 0.2s ease 0s forwards; }
      .ds-checkbox .checkmark.large input[type='checkbox']:checked + .check-box::before,
      .ds-checkbox .checkmark.large .check-box.checked::before {
        height: 36px;
        -webkit-animation: dothatopcheck-30px 0.4s ease 0s forwards;
        animation: dothatopcheck-30px 0.4s ease 0s forwards; }
  .ds-checkbox .checkmark.reversed input[type='checkbox']:checked + .check-box,
  .ds-checkbox .checkmark.reversed .check-box.checked {
    border-color: #fff; }
  .ds-checkbox .checkmark.reversed .check-box::before, .ds-checkbox .checkmark.reversed .check-box::after {
    width: 2px;
    background-color: #fff; }
  .ds-checkbox .checkmark.reversed .check-box::before {
    width: 0px; }
  .ds-checkbox .checktitle {
    font-weight: 300;
    text-transform: capitalize; }
