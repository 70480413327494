.core-pagination {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px 20px; }
  .core-pagination-button {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 2px;
    margin-right: 2px;
    width: 40px;
    height: 30px; }
    .core-pagination-button span {
      color: #376caf; }
      .core-pagination-button span.disabled {
        color: #676767;
        cursor: default; }
    .core-pagination-button.active {
      background-color: #f5f6f7; }
    .core-pagination-button.ctl {
      margin-left: 0;
      margin-right: 0;
      min-width: 60px;
      width: auto; }
      .core-pagination-button.ctl.left {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        padding-right: 20px; }
      .core-pagination-button.ctl.right {
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        padding-left: 20px; }
      .core-pagination-button.ctl:hover {
        background-color: transparent; }
        .core-pagination-button.ctl:hover span {
          font-weight: 500; }
    .core-pagination-button:hover {
      background-color: #f5f6f7; }
  .core-pagination-pers {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    min-width: 120px; }
  .core-pagination-per {
    color: #376caf;
    cursor: pointer;
    font-size: 14px;
    text-transform: capitalize; }
    .core-pagination-per.active {
      font-weight: bold; }
  .core-pagination.large-button span {
    font-size: 16px; }
  .core-pagination.large-pers {
    font-size: 16px; }
  .core-pagination.small-button span {
    font-size: 14px; }
  .core-pagination.small-pers {
    font-size: 14px; }
