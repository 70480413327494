.bell {
  display: -webkit-flex !important;
  display: flex !important;
  background-color: transparent !important;
  opacity: 0.5 !important;
  padding: 0 !important;
  width: 40px !important;
  height: 40px !important;
  min-width: 20px !important; }
  .bell:focus {
    background-color: transparent !important;
    color: white !important; }
  .bell:hover, .bell.active {
    opacity: 0.85 !important; }
    .bell:hover.search-box, .bell.active.search-box {
      background-color: #ffffff40 !important; }
  .bell .bell-icon {
    position: relative !important; }
    .bell .bell-icon .badge-icon {
      position: absolute !important;
      top: 0 !important;
      right: -3px !important;
      width: 15px !important;
      height: 15px !important;
      border-radius: 8px !important;
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-align-items: center !important;
              align-items: center !important;
      -webkit-justify-content: center !important;
              justify-content: center !important;
      background-color: red !important; }
      .bell .bell-icon .badge-icon > p {
        font-size: 8px !important;
        line-height: 8px !important; }

.popup-notification {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  cursor: pointer !important;
  margin: 0 20px !important; }

.notification-popup {
  -webkit-transform: translateY(0px) translateZ(0px) !important;
          transform: translateY(0px) translateZ(0px) !important; }
  .notification-popup .notification-container {
    height: 610px !important;
    overflow: auto !important; }
    .notification-popup .notification-container::-webkit-scrollbar {
      width: 5px !important;
      background-color: #fefefe !important; }
    .notification-popup .notification-container::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important; }
    .notification-popup .notification-container::-webkit-scrollbar-thumb {
      border-radius: 3px !important;
      background-color: #969faa !important;
      outline: 1px solid slategrey !important; }
    .notification-popup .notification-container .new-item {
      background-color: #e8f0f9 !important; }
    .notification-popup .notification-container .loading-spinner {
      height: 15px !important;
      width: 15px !important; }
    .notification-popup .notification-container .core-dropdown-title.dsl-m12 {
      color: #343f4b !important;
      font-size: 14px !important;
      font-weight: 400 !important; }
    .notification-popup .notification-container .core-dropdown .core-dropdown-toggle.horizontal .core-dropdown-label {
      padding-left: 0 !important; }
    .notification-popup .notification-container button .truncate-one {
      font-weight: 400 !important; }
    .notification-popup .notification-container .edit-dropdown i {
      font-size: 18px !important;
      color: #000 !important; }
