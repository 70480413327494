.dashboard {
  background-color: #f8f8f9;
  /* Custom, iPhone Retina */
  /* Extra Small Devices, Phones */
  /* Small Devices, Tablets */
  /* Medium Devices, Desktops */
  /* Large Devices, Wide Screens */ }
  @media only screen and (min-width: 320px) {
    .dashboard {
      width: 100%; } }
  @media only screen and (min-width: 480px) {
    .dashboard {
      width: 100%; } }
  @media only screen and (min-width: 768px) {
    .dashboard {
      width: 100%; } }
  @media only screen and (min-width: 992px) {
    .dashboard {
      width: 100%; } }
  @media only screen and (min-width: 1200px) {
    .dashboard {
      width: 100%; } }
  .dashboard .content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1;
            flex: 1;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    background-color: #f8f8f9;
    min-height: calc(100vh - 110px);
    position: relative; }
    .dashboard .content .main {
      -webkit-flex: 1;
              flex: 1;
      margin: 0 15px; }

.page {
  min-height: calc(100vh - 62px); }

.bold {
  font-weight: 700 !important; }

.text-100 {
  font-weight: 100 !important; }

.text-200 {
  font-weight: 200 !important; }

.text-300 {
  font-weight: 300 !important; }

.text-400 {
  font-weight: 400 !important; }

.text-500 {
  font-weight: 500 !important; }

.text-600 {
  font-weight: 600 !important; }

.text-700 {
  font-weight: 700 !important; }

.text-800 {
  font-weight: 800 !important; }

.text-900 {
  font-weight: 900 !important; }

.has-header {
  height: calc(100vh - 62px) !important; }

.has-sidebar {
  z-index: 1;
  width: calc(100vw - 246px) !important; }
  @media screen and (max-width: 1100px) {
    .has-sidebar {
      width: 100vw !important; } }

.left-sidebar {
  left: 0;
  width: 360px;
  float: left; }
  @media screen and (max-width: 768px) {
    .left-sidebar {
      position: initial;
      margin-top: 20px;
      padding-right: 20px;
      width: 100%; } }

.has-left-sidebar {
  margin-right: 20px;
  float: right;
  width: calc(100% - 360px - 20px); }

.dsl-l10 {
  color: #EDF5FF;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px; }

.dsl-l11 {
  color: #EDF5FF;
  font-size: 11px;
  font-weight: 300;
  line-height: 13px; }

.dsl-l12 {
  color: #EDF5FF;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px; }

.dsl-l13 {
  color: #EDF5FF;
  font-size: 13px;
  font-weight: 300;
  line-height: 17px; }

.dsl-l14 {
  color: #EDF5FF;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px; }

.dsl-l16 {
  color: #EDF5FF;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px; }

.dsl-d11 {
  color: #969faa;
  font-size: 11px;
  font-weight: 300;
  line-height: 13px; }

.dsl-d12 {
  color: #969faa;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px; }

.dsl-d13 {
  color: #969faa;
  font-size: 13px;
  font-weight: 300;
  line-height: 15px; }

.dsl-d14 {
  color: #969faa;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px; }

.dsl-d16 {
  color: #969faa;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px; }

.dsl-d22 {
  color: #969faa;
  font-size: 22px;
  font-weight: 300;
  line-height: 24px; }

.dsl-m10 {
  color: #676767;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px; }

.dsl-m11 {
  color: #676767;
  font-size: 11px;
  font-weight: 300;
  line-height: 12px; }

.dsl-m12 {
  color: #676767;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px; }

.dsl-m14 {
  color: #676767;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px; }
  @media screen and (max-width: 576px) {
    .dsl-m14 {
      font-size: 12px;
      line-height: 14px; } }

.dsl-m16 {
  color: #676767;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px; }
  @media screen and (max-width: 576px) {
    .dsl-m16 {
      font-size: 14px; } }

.dsl-m22 {
  color: #676767;
  font-size: 22px;
  font-weight: 300;
  line-height: 22px; }
  @media screen and (max-width: 576px) {
    .dsl-m22 {
      font-size: 18px; } }

.dsl-b10 {
  color: #343f4b;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px; }

.dsl-b11 {
  color: #343f4b;
  font-size: 11px;
  line-height: 13px; }

.dsl-b12 {
  color: #343f4b;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px; }
  @media screen and (max-width: 576px) {
    .dsl-b12 {
      font-size: 10px;
      line-height: 12px; } }

.dsl-b13 {
  color: #343f4b;
  font-size: 13px;
  font-weight: 300;
  line-height: 15px; }

.dsl-b14 {
  color: #343f4b;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px; }
  @media screen and (max-width: 576px) {
    .dsl-b14 {
      font-size: 12px;
      line-height: 16px; } }

.dsl-b15 {
  color: #343f4b;
  font-size: 15px;
  font-weight: 300;
  line-height: 20px; }

.dsl-b16 {
  color: #343f4b;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px; }
  @media screen and (max-width: 576px) {
    .dsl-b16 {
      font-size: 14px; } }

.dsl-b18 {
  color: #343f4b;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px; }
  @media screen and (max-width: 576px) {
    .dsl-b18 {
      font-size: 16px; } }

.dsl-b20 {
  color: #343f4b;
  font-size: 20px;
  font-weight: 300;
  line-height: 22px; }

.dsl-b22 {
  color: #343f4b;
  font-size: 22px;
  font-weight: 300;
  line-height: 24px; }
  @media screen and (max-width: 576px) {
    .dsl-b22 {
      font-size: 16px;
      line-height: 28px; } }

.dsl-b23 {
  color: #343f4b;
  font-size: 23px;
  font-weight: 300;
  line-height: 27px; }

.dsl-b24 {
  color: #343f4b;
  font-size: 24px;
  font-weight: 300;
  line-height: 26px; }
  @media screen and (max-width: 576px) {
    .dsl-b24 {
      font-size: 18px;
      line-height: 20px; } }

.dsl-b27 {
  color: #343f4b;
  font-size: 27px;
  font-weight: 300;
  line-height: 27px; }

.dsl-b28 {
  color: #343f4b;
  font-size: 28px;
  font-weight: 300;
  line-height: 28px; }

.dsl-b28 {
  color: #343f4b;
  font-size: 28px;
  font-weight: 300;
  line-height: 30px; }

.dsl-b34 {
  color: #343f4b;
  font-size: 34px;
  font-weight: 300;
  line-height: 36px; }

.dsl-b30 {
  color: #343f4b;
  font-size: 30px;
  font-weight: 300;
  line-height: 34px; }

.dsl-b50 {
  color: #343f4b;
  font-size: 50px;
  font-weight: 300;
  line-height: 52px; }

.dsl-p12 {
  color: #376caf;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px; }

.dsl-p13 {
  color: #376caf;
  font-size: 13px;
  font-weight: 300;
  line-height: 15px; }

.dsl-p14 {
  color: #376caf;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px; }
  @media screen and (max-width: 576px) {
    .dsl-p14 {
      font-size: 12px;
      line-height: 14px; } }

.dsl-p16 {
  color: #376caf;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px; }

.dsl-p18 {
  color: #376caf;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px; }

.dsl-p20 {
  color: #376caf;
  font-size: 20px;
  font-weight: 300;
  line-height: 22px; }

.dsl-p25 {
  color: #376caf;
  font-size: 25px;
  font-weight: 300;
  line-height: 27px; }

.dsl-w10 {
  color: #ffffff;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px; }

.dsl-w12 {
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px; }

.dsl-w14 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px; }
  @media screen and (max-width: 576px) {
    .dsl-w14 {
      font-size: 12px; } }

.dsl-w16 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px; }

.dsl-w18 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px; }

.dsl-w22 {
  color: #ffffff;
  font-size: 22px;
  font-weight: 300;
  line-height: 24px; }

.dsl-w24 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  line-height: 26px; }

.dsl-w28 {
  color: #ffffff;
  font-size: 28px;
  font-weight: 300;
  line-height: 30px; }

.dsl-w30 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 300;
  line-height: 34px; }

.dsl-w36 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 300;
  line-height: 38px; }

.dsl-w50 {
  color: #ffffff;
  font-size: 50px;
  font-weight: 300;
  line-height: 52px; }

.dsl-r10 {
  color: red;
  font-size: 10px;
  font-weight: 300;
  line-height: 16px; }

.dsl-r12 {
  color: red;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px; }
  @media screen and (max-width: 576px) {
    .dsl-r12 {
      font-size: 10px; } }

.dsl-r13 {
  color: red;
  font-size: 13px;
  font-weight: 300;
  line-height: 15px; }

.dsl-r14 {
  color: red;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px; }

.half-spacing {
  letter-spacing: -0.5px; }

.btn-rounded {
  border-radius: 0px; }

.ds-btn {
  color: #ffffff;
  padding: 7px 25px; }
  .ds-btn:hover {
    background-color: #6899d7;
    border-color: transparent;
    color: #ffffff; }

.ds-btn-primary {
  background-color: #376caf;
  border-color: transparent;
  color: #ffffff;
  font-size: 13px;
  font-weight: 200;
  padding: 10px 30px; }
  .ds-btn-primary:hover {
    background-color: #6899d7;
    border-color: transparent;
    color: #ffffff; }

.ds-btn-primary[disabled] {
  background-color: #969faa; }
  .ds-btn-primary[disabled]:hover {
    background-color: #969faa;
    border-color: transparent; }

.css-10nd86i {
  font-size: 14px;
  min-width: 80px; }
  .css-10nd86i .css-vj8t7z {
    border: none; }
    .css-10nd86i .css-vj8t7z .css-1hwfws3 {
      padding: 0; }
      .css-10nd86i .css-vj8t7z .css-1hwfws3 .css-1492t68 {
        color: #343f4b; }
  .css-10nd86i .css-d8oujb {
    display: none; }
  .css-10nd86i .css-1ep9fjw svg,
  .css-10nd86i .css-1wy0on6 svg {
    color: #343f4b;
    width: 14px;
    height: 14px; }

.d-flex-0-5 {
  -webkit-flex: 0.5;
          flex: 0.5; }

.d-flex-1 {
  -webkit-flex: 1;
          flex: 1; }

.d-flex-2 {
  -webkit-flex: 2;
          flex: 2; }

.d-flex-3 {
  -webkit-flex: 3;
          flex: 3; }

.d-flex-4 {
  -webkit-flex: 4;
          flex: 4; }

.d-flex-5 {
  -webkit-flex: 5;
          flex: 5; }

.d-flex-6 {
  -webkit-flex: 6;
          flex: 6; }

.d-flex-7 {
  -webkit-flex: 7;
          flex: 7; }

.d-flex-8 {
  -webkit-flex: 8;
          flex: 8; }

.d-flex-9 {
  -webkit-flex: 9;
          flex: 9; }

.d-flex-10 {
  -webkit-flex: 10;
          flex: 10; }

.d-flex-11 {
  -webkit-flex: 11;
          flex: 11; }

.d-flex-12 {
  -webkit-flex: 12;
          flex: 12; }

.d-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.d-h-end {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.d-h-start {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.d-h-between {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.d-flex-col {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

@media screen and (max-width: 768px) {
  .d-flex-sm-1 {
    -webkit-flex: 1;
            flex: 1; }
  .d-flex-sm-2 {
    -webkit-flex: 2;
            flex: 2; }
  .d-flex-sm-3 {
    -webkit-flex: 3;
            flex: 3; }
  .d-flex-sm-4 {
    -webkit-flex: 4;
            flex: 4; } }

@media screen and (max-width: 576px) {
  .d-flex-ssm-0 {
    -webkit-flex: 0;
            flex: 0; }
  .d-flex-ssm-1 {
    -webkit-flex: 1;
            flex: 1; }
  .d-flex-ssm-2 {
    -webkit-flex: 2;
            flex: 2; }
  .d-flex-ssm-3 {
    -webkit-flex: 3;
            flex: 3; }
  .d-flex-ssm-4 {
    -webkit-flex: 4;
            flex: 4; }
  .d-flex-ssm-5 {
    -webkit-flex: 5;
            flex: 5; }
  .d-flex-ssm-6 {
    -webkit-flex: 6;
            flex: 6; }
  .d-flex-ssm-7 {
    -webkit-flex: 7;
            flex: 7; }
  .d-flex-ssm-12 {
    -webkit-flex: 12;
            flex: 12; }
  .text-wrap-ssm {
    width: 10em;
    height: 20px;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis; }
  .flex-ssm-unset {
    -webkit-flex: unset;
            flex: unset; } }

.border-0 {
  border: none !important; }

.border-r0 {
  border-radius: 0; }

.border-5 {
  border-radius: 5px !important; }

.border-top,
.border {
  border-top: 1px solid #EDF5FF; }

.border-right,
.border {
  border-right: 1px solid #EDF5FF; }

.border-bottom,
.border {
  border-bottom: 1px solid #EDF5FF; }

.border-left,
.border {
  border-left: 1px solid #EDF5FF; }

.border-bottom-light {
  border-bottom: 1px solid #f8f8f9; }

.border-top-light {
  border-top: 1px solid #f8f8f9; }

@media screen and (max-width: 768px) {
  .custom-right-border-sm {
    border-right: 1px solid #e2e3e5;
    height: 70px; } }

@media screen and (max-width: 576px) {
  .custom-br-ssm {
    border-right: 1px solid #e2e3e5;
    height: 70px;
    padding-right: 5px; }
  .bold-ssm {
    font-weight: 700 !important; } }

.rounded-circle {
  border-radius: 50% !important; }

.h-100vh {
  height: 100vh !important; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-60 {
  width: 60% !important; }

.bg-main {
  background-color: #f8f8f9; }

.bg-light {
  background-color: #f8f8f9; }

.bg-primary {
  background-color: #376caf; }

.bg-white {
  background-color: #ffffff !important; }

.bg-light-grey {
  background-color: #969faa !important; }

.bg-slight-grey {
  background-color: #EDF5FF !important; }

.bg-red {
  background-color: #9a0909 !important; }

.bg-yellow {
  background-color: #fdff7a; }

.bg-none {
  background-color: transparent !important; }

.main-blue {
  color: #376caf !important; }

.light-grey {
  color: #969faa !important; }

.color-white {
  color: white !important; }

.color-black {
  color: black !important; }

.color-dark {
  color: #343f4b !important; }

.color-red {
  color: red !important; }

.color-basic {
  color: green !important; }

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.9;
  background-color: #000; }

.position-static {
  position: static; }

.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

.position-fixed {
  position: fixed; }

.position-sticky {
  position: -webkit-sticky;
  position: sticky; }

.deactivate,
.opacity-5 {
  opacity: 0.5 !important; }

.cursor-pointer {
  cursor: pointer !important; }

.overflow-scroll,
.overflow-scroll-y {
  overflow-y: scroll; }

.overflow-scroll,
.overflow-scroll-x {
  overflow-x: scroll; }

.overflow-hidden,
.overflow-hidden-y {
  overflow-y: hidden; }

.overflow-hidden,
.overflow-hidden-x {
  overflow-x: hidden; }

.overflow-auto,
.overflow-auto-x {
  overflow-x: auto; }

.overflow-auto,
.overflow-auto-y {
  overflow-y: auto; }

.font-italic {
  font-style: italic; }

.text-line-through {
  text-decoration: line-through; }

.text-underline {
  text-decoration: underline !important; }

.text-line-none {
  text-decoration: none; }

.text-capitalize {
  text-transform: capitalize; }

.text-vcenter {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center; }

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.truncate-one {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis; }

.truncate-two {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis; }

.truncate-three {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis; }

.truncate-four {
  display: -webkit-box;
  margin: 0 auto;
  line-height: 1.4;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis; }

.no-wrap {
  white-space: nowrap; }

.wrap {
  white-space: normal; }

.vert-line {
  height: 0;
  width: 2px;
  background: #e0e0e0;
  margin: 0 20px; }
  @media screen and (max-width: 768px) {
    .vert-line {
      height: 24px; } }
  @media screen and (max-width: 576px) {
    .vert-line {
      height: 0;
      margin: 0 5px; } }

.card {
  background-color: #fff;
  border-radius: 5px;
  border: none;
  padding: 30px 20px;
  margin-bottom: 10px; }
  @media screen and (max-width: 768px) {
    .card {
      margin-right: 0 !important;
      margin-left: 0 !important; } }

@media screen and (max-width: 768px) {
  .dsl-l11 {
    color: #343f4b;
    line-height: normal;
    margin-bottom: 0; } }

.desktop-screen {
  display: block; }
  @media (max-width: 576px) {
    .desktop-screen {
      display: none; } }

.mobile-screen {
  display: none; }
  @media (max-width: 576px) {
    .mobile-screen {
      display: block; } }

.tablet-screen {
  display: block; }
  @media (max-width: 768px) {
    .tablet-screen {
      display: none; } }

.circle {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #969faa;
  border-radius: 13px;
  color: white;
  font-size: 11px;
  margin: 2px 5px 0 0;
  width: 20px;
  height: 20px; }

.custom-round {
  margin-top: 5px;
  margin-bottom: 3px;
  height: 10px;
  width: 10px;
  background-color: #e1e2e4;
  border-radius: 50%; }

.custom-round-active {
  background-color: #343f4b; }

.disabled,
.md-disabled {
  pointer-events: none; }
  .disabled i,
  .md-disabled i {
    color: #EDF5FF !important; }

.card-header {
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: none;
  padding: 30px 20px; }

.card-content {
  background-color: #fff;
  padding: 0 20px; }
  .card-content .nav-link {
    margin-bottom: 15px; }

.card-bottom {
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  padding: 30px 20px;
  margin-bottom: 10px; }

.box-shadow {
  box-shadow: -2px 2px 10px 4px rgba(0, 0, 0, 0.1); }

.display-none {
  display: none; }

.desktop-flex {
  display: -webkit-flex !important;
  display: flex !important; }
  @media (max-width: 768px) {
    .desktop-flex {
      display: none !important; } }

@media screen and (max-width: 768px) {
  .d-flex.sm-column {
    -webkit-flex-direction: column;
            flex-direction: column; } }

@media screen and (max-width: 768px) {
  .align-items-center.sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; } }

.tablet-block {
  display: none; }
  @media (max-width: 768px) {
    .tablet-block {
      display: block; } }

@media screen and (max-width: 576px) {
  .text-right.ssm-left {
    text-align: left !important; } }
