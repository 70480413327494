.actions:hover .actions-modal {
  display: unset; }

.actions-modal {
  display: none;
  padding: 12px;
  position: absolute;
  z-index: 999;
  width: 125px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05); }
