.core-dropdown {
  margin-bottom: auto;
  position: relative; }
  .core-dropdown .core-dropdown-toggle {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    height: 100%; }
    .core-dropdown .core-dropdown-toggle .core-dropdown-label {
      overflow: hidden;
      line-height: 22px;
      margin-right: auto;
      position: relative;
      text-overflow: ellipsis;
      max-width: 400px; }
      .core-dropdown .core-dropdown-toggle .core-dropdown-label span {
        display: inline-block;
        min-width: 20px;
        font-size: 15px;
        font-weight: 400; }
      .core-dropdown .core-dropdown-toggle .core-dropdown-label:hover {
        background-color: #f7f7f7; }
    .core-dropdown .core-dropdown-toggle .caret {
      position: absolute;
      bottom: 40% !important;
      right: 10px; }
    .core-dropdown .core-dropdown-toggle.horizontal {
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center; }
      .core-dropdown .core-dropdown-toggle.horizontal .core-dropdown-label {
        padding: 7px 10px; }
    .core-dropdown .core-dropdown-toggle.vertical {
      -webkit-flex-direction: column;
              flex-direction: column; }
      .core-dropdown .core-dropdown-toggle.vertical .core-dropdown-label {
        padding-right: 10px; }
  .core-dropdown .core-dropdown-menu {
    background-color: white;
    border-radius: 5px;
    box-shadow: -2px 2px 10px 4px rgba(0, 0, 0, 0.1);
    max-height: 45vh;
    overflow-y: auto;
    padding: 10px;
    margin-top: 10px;
    position: absolute;
    z-index: 999; }
    .core-dropdown .core-dropdown-menu .core-dropdown-item {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      cursor: pointer;
      min-height: 30px;
      padding: 0 10px; }
      .core-dropdown .core-dropdown-menu .core-dropdown-item:hover {
        background-color: #f7f7f7; }
      .core-dropdown .core-dropdown-menu .core-dropdown-item.active {
        background-color: #e7eff8; }
    .core-dropdown .core-dropdown-menu .split-line {
      background-color: #cccccc;
      margin-top: 15px;
      margin-bottom: 5px;
      width: 100%;
      height: 0.5px; }
    .core-dropdown .core-dropdown-menu.left {
      left: 0; }
    .core-dropdown .core-dropdown-menu.right {
      right: 0; }

@media screen and (max-width: 576px) {
  .custom-dropdown-mobile .core-dropdown-toggle .core-dropdown-label {
    max-width: 300px; }
    .custom-dropdown-mobile .core-dropdown-toggle .core-dropdown-label span {
      color: #376caf; }
  .custom-dropdown-mobile .core-dropdown-toggle .caret {
    color: #376caf !important; }
  .custom-dropdown-mobile .core-dropdown-menu.left {
    margin-left: 15px;
    width: 130px; } }
