body::-webkit-scrollbar {
  width: 5px;
  background-color: #fefefe; }

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #000; }

body::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: darkgrey;
  outline: 1px solid slategrey; }

.modal-content {
  border: none; }
  .modal-content .modal-header {
    background-color: #fff;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .modal-content .modal-footer {
    border-top: none; }

body {
  font-family: 'Montserrat Vanne', sans-serif; }

.DateRangePicker__CalendarSelection {
  background-color: #eaf2ff !important;
  border-color: #eaf2ff !important; }

.DateRangePicker__Date--is-selected {
  color: unset !important; }

.DateRangePicker__CalendarHighlight--single {
  border-color: #d7e6ff !important; }

#titlediv {
  display: none; }

.Toastify__toast-container {
  min-width: 50px; }

.Toastify__toast {
  display: block !important;
  border-radius: 6px !important;
  padding: 0 !important;
  overflow: visible !important;
  float: right; }
  .Toastify__toast--success {
    width: 100% !important;
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
    min-height: 30px; }
    .Toastify__toast--success .Toastify__toast-body::before {
      margin: 0 5px;
      left: 0.012em;
      content: '\f058';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 300;
      color: #16ac10;
      padding: 2px 4px;
      width: 25px;
      height: 25px; }
  .Toastify__toast--warning {
    width: 100% !important;
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important; }
    .Toastify__toast--warning .Toastify__toast-body::before {
      margin: 0 5px;
      left: 0.012em;
      content: '\f05a';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 300;
      color: #ff9100;
      padding: 2px 8px;
      width: 25px;
      height: 25px; }
  .Toastify__toast--error {
    width: 100% !important;
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important; }
    .Toastify__toast--error .Toastify__toast-body::before {
      margin: 0 5px;
      left: 0.012em;
      content: '\f057';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 300;
      color: #ff0000;
      padding: 2px 6px;
      width: 25px;
      height: 25px; }
  .Toastify__toast-body {
    display: inline-block;
    -webkit-flex: 0 !important;
            flex: 0 !important;
    float: right;
    padding: 16px 20px 16px 38px;
    border-radius: 6px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    color: #343f4b !important;
    text-indent: -35px;
    white-space: pre-line; }

.Toastify__close-button {
  position: absolute;
  right: 0 !important;
  overflow: hidden;
  text-indent: -60px; }

.Toastify__close-button::after {
  margin-right: 5px;
  left: 0.012em;
  content: '\f00d';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  color: #c3c7cc;
  padding: 2px 6px;
  width: 25px;
  height: 25px;
  float: left;
  text-indent: 0; }

.Toastify__progress-bar {
  background-color: rgba(255, 255, 255, 0) !important; }

.zsiq_theme1 .zsiq_flt_rel {
  background-color: #376caf !important; }

.zsiq_theme1 .zsiq_user.siqico-chat {
  background-color: #376caf !important; }

@media screen and (max-width: 576px) {
  .zsiq_theme1 {
    display: none !important; } }

.quill .ql-toolbar {
  background-color: white; }

.quill .ql-editor {
  background-color: #f6f7f8; }

.nav-tabs {
  background-color: transparent;
  border-bottom: none;
  padding-top: 10px;
  padding-bottom: 10px; }
  .nav-tabs .nav-item {
    background-color: transparent !important;
    border-color: #969faa !important;
    border: none;
    font-size: 14px;
    font-weight: 300;
    padding: 0 15px;
    border-right: 1px solid #969faa;
    border-radius: 0; }
    .nav-tabs .nav-item.active {
      color: #376caf;
      font-weight: 700;
      text-decoration: underline; }
    .nav-tabs .nav-item:focus {
      outline: unset; }
    .nav-tabs .nav-item:first-child {
      padding-left: 0; }
    .nav-tabs .nav-item:last-child {
      border-right: 0 !important; }
  .nav-tabs:focus {
    outline: none; }

.form-control::-webkit-input-placeholder {
  font-size: 14px; }

.form-control::-moz-placeholder {
  font-size: 14px; }

.form-control:-ms-input-placeholder {
  font-size: 14px; }

.form-control::-ms-input-placeholder {
  font-size: 14px; }

.form-control::placeholder {
  font-size: 14px; }
