.loading-animation {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.1); }
  .loading-animation .loading-block .sk-spinner.small {
    width: 27px !important;
    height: 27px !important; }
  .loading-animation .loading-block .sk-spinner.medium {
    width: 54px !important;
    height: 54px !important; }
  .loading-animation .loading-block .sk-spinner.large {
    width: 81px !important;
    height: 81px !important; }
  .loading-animation .loading-block span {
    color: #376caf; }
    .loading-animation .loading-block span.small {
      font-size: 14px; }
    .loading-animation .loading-block span.medium {
      font-size: 18px; }
    .loading-animation .loading-block span.large {
      font-size: 22px; }
