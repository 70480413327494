@media screen and (min-width: 576px) {
  .app-modal.xlarge .modal-dialog {
    max-width: 1000px; } }

@media screen and (min-width: 576px) {
  .app-modal.mlarge .modal-dialog {
    max-width: 900px; } }

@media screen and (min-width: 576px) {
  .app-modal.large .modal-dialog {
    max-width: 800px; } }

@media screen and (min-width: 576px) {
  .app-modal.medium .modal-dialog {
    max-width: 600px; } }

@media screen and (min-width: 576px) {
  .app-modal.small .modal-dialog {
    max-width: 470px; } }

@media screen and (min-width: 576px) {
  .app-modal.confirm-modal .modal-dialog {
    max-width: 500px; }
  .app-modal.confirm-modal .modal-content {
    margin: 12rem 0; } }

.app-modal.bg-transparent .modal-dialog .modal-content {
  background-color: transparent; }

.app-modal {
  padding: 0 24px; }
  @media screen and (max-width: 768px) {
    .app-modal {
      padding: 0 10px 0 10px; } }
  @media screen and (max-width: 576px) {
    .app-modal {
      padding: 40px 0 40px 0; } }

.backdrop-custom {
  background-color: red !important; }
