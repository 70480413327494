@media (min-width: 768px) {
  .confirmation-modal .modal-dialog {
    width: 460px;
    margin-top: 10%; } }

.confirmation-modal *:focus {
  outline: 0 !important; }

.confirmation-modal .modal-header {
  background-color: #ffffff;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  border: none; }

.confirmation-modal .modal-body {
  -webkit-flex-direction: column;
          flex-direction: column; }
